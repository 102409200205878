import { Typography, useTheme } from '@mui/material';
import { CardActionArea } from '@mui/material';
import { Box } from '@mui/material';
import parse from 'html-react-parser';
import {
  ElipsedTypography,
  StyledLabel,
  StyledImage,
  StyledPaper,
  StyledChip,
} from './ArticleCard.style';
import { IArticleCardProps } from './ArticleCard.types';
import { Iconify } from 'components/Iconify/Iconify';

const ArticleCard = ({
  body,
  date,
  image,
  title,
  id,
  onClick,
}: IArticleCardProps) => {
  const theme = useTheme();
  return (
    <StyledPaper>
      <CardActionArea onClick={() => onClick?.(id)}>
        <Box sx={{ padding: '30px 33px 50px' }}>
          <StyledChip
            variant="filled"
            label={date}
            bgColor={theme.palette['info'].darker}
          />
          <div style={{ overflow: 'hidden', minHeight: '76px' }}>
            <ElipsedTypography
              variant="h5"
              sx={{
                mt: 2,
                lineHeight: '30px',
              }}
              maxLine={2}
            >
              {title}
            </ElipsedTypography>
          </div>
          <StyledImage src={image} sx={{ my: 2 }}>
            <StyledLabel>
              <Iconify
                icon={'material-symbols:article-outline'}
                style={{
                  fontSize: '14px',
                  color: '#CC9200',
                  marginRight: 5,
                  marginBottom: 3,
                  fill: 'none',
                }}
              />
              <Typography variant={'body2'}>Articolo</Typography>
            </StyledLabel>
          </StyledImage>
          <div
            style={{ overflow: 'hidden', height: 'auto', maxHeight: '88px' }}
          >
            <ElipsedTypography
              variant="body1"
              sx={{
                lineHeight: '22px',
              }}
              maxLine={4}
            >
              {parse(body)}
            </ElipsedTypography>
          </div>
        </Box>
      </CardActionArea>
    </StyledPaper>
  );
};

export { ArticleCard };
