import { IntlShape } from 'react-intl';

const htmlParserOptions = {
  br: () => <br />,
  email: (str: string) => (
    <a
      href={'mailto: finanziamenti@federlegnoarredo.it'}
      rel="noreferrer"
      style={{ color: 'black' }}
    >
      {str}
    </a>
  ),
};

export const financeTranslations = (intl: IntlShape) => ({
  finance: {
    contact: {
      footerText: intl.formatMessage(
        {
          defaultMessage:
            'Compila il form per richiedere più informazioni sulla finanza agevolata. Se preferisci contattarci direttamente puoi<br></br> scriverci a <email>finanziamenti@federlegnoarredo.it</email> o telefonare al +39 342 3294942',
          description: 'finance contact footerText',
        },
        { ...htmlParserOptions }
      ),
    },
    home: {
      title: intl.formatMessage({
        defaultMessage:
          'Assistenza ai finanziamenti agevolati e misure attive in evidenza',
        description: 'finance home title',
      }),
    },
    webinar: {
      title: intl.formatMessage({
        defaultMessage: 'Approfondimenti',
        description: 'finance webinar title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          'Sezione dedicata ai percorsi informativi e formativi con brevi approfondimenti sui temi della finanza aziendale.',
        description: 'finance webinar subtitle',
      }),
    },
    list: {
      title: intl.formatMessage({
        defaultMessage: 'Assistenza ai finanziamenti agevolati',
        description: 'finance list title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          'Troverai tutti gli aggiornamenti e le misure attive con la possibilità di richiedere consulenza attraverso il form di contatto.',
        description: 'finance list subtitle',
      }),
      others: intl.formatMessage({
        defaultMessage: 'Altri argomenti e misure che potrebbero interessarti',
        description: 'finance list others',
      }),
      contact: {
        title: intl.formatMessage({
          defaultMessage: 'Richiedi più informazioni',
          description: 'finance list contact title',
        }),
        subtitle: intl.formatMessage({
          defaultMessage:
            'Compila il form per richiedere più informazioni su questa misura.',
          description: 'finance list contact subtitle',
        }),
      },
      form: {
        results: {
          measures: intl.formatMessage({
            defaultMessage: 'Aggiornamenti e misure attive',
            description: 'finance list form results measures',
          }),
          text: intl.formatMessage({
            defaultMessage: 'risultati',
            description: 'finance list form results text',
          }),
        },
        search: intl.formatMessage({
          defaultMessage: 'Ricerca contenuto di interesse',
          description: 'finance list form results search',
        }),
        asc: intl.formatMessage({
          defaultMessage: 'Ordina per data meno recente',
          description: 'finance list form asc',
        }),
        desc: intl.formatMessage({
          defaultMessage: 'Ordina per data più recente',
          description: 'finance list form desc',
        }),
      },
    },
    detail: {
      download: intl.formatMessage({
        defaultMessage: 'Scarica articolo',
        description: 'finance detail download',
      }),
      highlights: intl.formatMessage({
        defaultMessage: 'INFORMAZIONI IN EVIDENZA',
        description: 'finance detail highlights',
      }),
      measureType: intl.formatMessage({
        defaultMessage: 'Agevolazione di carattere',
        description: 'finance detail measureType',
      }),
      region: intl.formatMessage({
        defaultMessage: 'regionale',
        description: 'finance detail region',
      }),
      national: intl.formatMessage({
        defaultMessage: 'nazionale',
        description: 'finance detail nation',
      }),
      local: intl.formatMessage({
        defaultMessage: 'locale',
        description: 'finance detail local',
      }),
      european: intl.formatMessage({
        defaultMessage: 'europea',
        description: 'finance detail european',
      }),
    },
  },
});
