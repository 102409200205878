import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  Link,
  useTheme,
} from '@mui/material';

import { useState } from 'react';
// @mui
// components
import { Link as RouterLink } from 'react-router-dom';
import { MenuPopover } from 'components';
import { IconButtonAnimate } from 'components/animate';
import { PERSONAL_PROFILE } from 'constants/paths';
import { PLACEHOLDER_USER_IMAGE } from 'constants/user';
import { useTranslations } from 'hooks';
import { useAuthSelector } from 'stores';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AccountPopover({ isOffset }: { isOffset: boolean }) {
  //hooks
  const theme = useTheme();
  const i18n = useTranslations();
  const { user, handleLogout } = useAuthSelector();
  //states
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  //constants
  const MENU_OPTIONS = [
    {
      label: i18n.auth.personalProfile.tabs.info,
      linkTo: PERSONAL_PROFILE,
      type: 'button',
    },
    {
      label: i18n.auth.personalProfile.tabs.password,
      linkTo: PERSONAL_PROFILE + '?activeTab=1',
      type: 'button',
    },
    {
      label: i18n.auth.personalProfile.tabs.interests,
      linkTo: PERSONAL_PROFILE + '?activeTab=2',
      type: 'button',
    },
    {
      label: i18n.auth.personalProfile.tabs.community,
      linkTo: PERSONAL_PROFILE + '?activeTab=3',
      type: 'button',
    },
    {
      label: i18n.auth.personalProfile.tabs.feedback,
      linkTo: PERSONAL_PROFILE + '?activeTab=4',
      type: 'button',
    },

    // {
    //   label: i18n.common.buttons.openWebSite,
    //   linkTo: BASE_FO,
    //   type: 'link',
    // },
  ];

  //functions
  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
            },
          }),
        }}
      >
        <Avatar
          src={
            user?.user_picture
              ? `${process.env.REACT_APP_HOST}${user?.user_picture}`
              : PLACEHOLDER_USER_IMAGE
          }
          alt="Rayan Moran"
        />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          width: '220px',
          p: 0,
          mt: 1.5,
          ml: 0.75,
          top: `${isOffset ? 56 : 90}px !important`,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 2, px: 2.5 }}>
          <Typography
            variant="subtitle1"
            noWrap
            color={theme.palette.primary.dark}
          >
            {user?.field_name && user?.field_surname
              ? `${user?.field_name} ${user?.field_surname}`
              : user?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider />

        <Stack sx={{ p: 1 }} gap={1}>
          {MENU_OPTIONS.map((option, idx) => (
            <MenuItem key={option.label} onClick={handleClose}>
              {option.type === 'link' ? (
                <a href={option.linkTo} target="_blank">
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.primary.dark,
                      textDecoration: 'underline',
                      ...(idx === 0 && { mt: 0.5 }),
                      ...(idx === MENU_OPTIONS.length - 1 && { mb: 0.5 }),
                    }}
                  >
                    {option.label}
                  </Typography>
                </a>
              ) : (
                <Link
                  variant="body2"
                  component={RouterLink}
                  to={option.linkTo}
                  sx={{
                    ...(idx === 0 && { mt: 0.5 }),
                    ...(idx === MENU_OPTIONS.length - 1 && { mb: 0.5 }),
                    width: '100%',
                    color: theme.palette.primary.dark,
                  }}
                >
                  {option.label}
                </Link>
              )}
            </MenuItem>
          ))}
        </Stack>

        <Divider />

        <MenuItem sx={{ m: 1 }} onClick={handleLogout}>
          <Typography variant="body2">{i18n.common.buttons.logout}</Typography>
        </MenuItem>
      </MenuPopover>
    </>
  );
}
