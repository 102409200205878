import { FormProvider as Form } from 'react-hook-form';
import { CustomFormProviderProps } from './FormProvider.types';

const FormProvider = ({
  children,
  onSubmit,
  methods,
  onKeyDown,
  ...other
}: CustomFormProviderProps) => (
  <Form {...methods}>
    <form onSubmit={onSubmit} noValidate {...other} onKeyDown={onKeyDown}>
      {children}
    </form>
  </Form>
);

export { FormProvider };
