import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import { LoadingScreen } from 'components';
import * as PATHS from 'constants/paths';
import { isToolEnabled } from 'constants/user';
import { AuthGuard, GuestGuard } from 'guards';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';
import AuthLayout from 'layouts/auth/AuthLayout';
import DashboardLayout from 'layouts/dashboard';
import { ContextEnum } from 'types/content';

// components

// ----------------------------------------------------------------------

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Loadable = (Component: ElementType) => (props: any) => {
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/dashboard" replace />,
    },
    {
      path: PATHS.DASHBOARD,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        // { path: '/dashboard', element: <Dashboard />, index: true },
        { path: '/dashboard', element: <DashboardV2 />, index: true },
        {
          path: PATHS.CONTENT_DETAIL_ID,
          element: <Content />,
        },
        // { path: PATHS.FAQ_ROUTE, element: <FAQ /> },
        { path: 'stepper-view', element: <StepperView /> },
        { path: PATHS.NOTIFICATIONS_ROUTE, element: <Notifications /> },
        { path: PATHS.CERTIFICATION, element: <Certification /> },
        { path: PATHS.CERTIFICATION_DYNAMIC_ID, element: <CertificationTab /> },
        {
          path: PATHS.CERTIFICATION_MORE_CONTENT,
          element: <CertificationMoreContent />,
        },
        {
          path: PATHS.CUSTOMHOUSEOFFICE,
          element: <CustomHouseOffice />,
        },
        {
          path: PATHS.CONTENT_TYPE,
          element: <ContentType />,
        },
        {
          path: PATHS.ACADEMY_CONTENT_TYPE,
          element: <ContentType context={ContextEnum.ACADEMY} />,
        },
        {
          path: PATHS.INTELLECTUALPROPERTY_CONTENT_TYPE,
          element: <ContentType context={ContextEnum.INTELLECTUAL_PROPERTY} />,
        },
        // TODO: Remove when Dogane course will be on Drupal
        {
          path: PATHS.CONTENT_MASTER,
          element: <Master />,
        },
        {
          path: PATHS.CONTENT_EXPERT,
          element: <Expert />,
        },
        {
          path: PATHS.CUSTOMHOUSEOFFICE_SERVICES,
          element: <CustomHouseServices />,
        },
        { path: PATHS.MATERIALLIBRARY, element: <MaterialLibrary /> },
        { path: PATHS.MATERIALDETAILS, element: <MaterialDetails /> },
        {
          path: PATHS.MATERIAL_PRODUCER_DETAILS,
          element: <MaterialProducerDetail />,
        },
        {
          path: PATHS.CIRCULARITYTOOL,
          element: isToolEnabled ? <CircularityTool /> : <></>,
        },
        {
          path: PATHS.CIRCULARITYTOOL_NEW_SURVEY,
          element: isToolEnabled ? <NewSurvey /> : <></>,
        },
        { path: PATHS.DESIGNWOODS, element: <DesignWoods /> },
        { path: PATHS.GENERALSURVEY, element: <GeneralSurvey /> },
        { path: PATHS.GENERALSURVEY_NEW, element: <SurveyForm /> },
        {
          path: PATHS.ACADEMY,
          element: <Academy />,
        },
        { path: PATHS.ACADEMYFLA, element: <AcademyType /> },
        { path: PATHS.ACADEMYARCHITECTS, element: <AcademyType /> },
        { path: PATHS.ACADEMYFLA_CATEGORY_ID, element: <AcademyCategory /> },
        { path: PATHS.ACADEMYFLA_ID, element: <AcademyDetail /> },
        {
          path: PATHS.ACADEMYFLA_ID_FINANCE_REQ,
          element: <AcademyFinanceReq />,
        },
        {
          path: PATHS.ACADEMYFLA_ID_MORE_INFO,
          element: <AcademyMoreInfoReq />,
        },
        { path: PATHS.ACADEMYARCHITECTS_ID, element: <AcademyDetail /> },
        { path: PATHS.INTELLECTUALPROPERTY, element: <IntellectualProperty /> },
        { path: PATHS.INTELLECTUAL_CONVENTION, element: <IPConvention /> },
        { path: PATHS.INTELLECTUAL_COURSE, element: <IPCourse /> },
        { path: PATHS.INTELLECTUAL_ON_DEMAND_LIST, element: <OnDemandList /> },
        { path: PATHS.INTELLECTUAL_LIST, element: <IntellectualList /> },
        { path: PATHS.INTELLECTUAL_DETAIL, element: <IntellectualDetail /> },
        {
          path: PATHS.INTELLECTUAL_ON_DEMAND_DETAIL,
          element: <OnDemandDetail />,
        },
        { path: PATHS.FINANCEANDTAX, element: <FinanceAndTax /> },
        { path: PATHS.FINANCE_LIST, element: <FinanceList /> },
        { path: PATHS.FINANCE_DETAIL, element: <FinanceDetail /> },
        { path: PATHS.FINANCE_WEBINAR_LIST, element: <FinanceWebinarList /> },
        {
          path: PATHS.FINANCE_WEBINAR_DETAIL,
          element: <FinanceWebinarDetail />,
        },
        { path: PATHS.EDUCATION, element: <Library /> },
        // { path: PATHS.EDUCATION, element: <WorkInProgressLibrary /> },
        {
          path: PATHS.STUDYCENTER,
          element: <StudyCenter />,
        },
        {
          path: PATHS.STUDYCENTER_CATEGORIES_LIST,
          element: <StudyCenterCategoriesList />,
        },
        {
          path: PATHS.STUDYCENTER_PRODUCT_CATEGORY,
          element: <StudyCenterCategory />,
        },
        { path: PATHS.STUDYCENTER_DETAIL, element: <StudyCenterDetails /> },
        {
          path: PATHS.PERSONAL_PROFILE,
          element: <PersonalProfile />,
        },
        { path: PATHS.ITALIAN_WOOD_STOCK, element: <ItalianWoodExchange /> },
      ],
    },
    {
      path: PATHS.AUTH,
      element: <AuthLayout />,
      children: [
        { element: <Navigate to="/auth/login" replace />, index: true },
        {
          path: PATHS.LOGIN,
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: PATHS.REGISTRATION,
          element: (
            <GuestGuard>
              <Registration />
            </GuestGuard>
          ),
        },
        {
          path: PATHS.REGISTER_CONFIRMATION,
          element: (
            <GuestGuard>
              <RegistrationComplete />
            </GuestGuard>
          ),
        },
        { path: PATHS.RECOVERY_PASSWORD, element: <RecoveryPassword /> },
        {
          path: PATHS.RECOVERY_PASSWORD_EMAIL_SENT,
          element: <RecoveryPasswordEmailSent />,
        },
        {
          path: PATHS.PASSWORD_CONFIRMATION,
          element: <PasswordConfirmation />,
        },
        { path: PATHS.RESET_PASSWORD, element: <ResetPassword /> },
        // { path: PATHS.SET_PASSWORD, element: <SetPassword /> },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Dashboard
const NotFound = Loadable(lazy(() => import('pages/Page404')));
const StepperView = Loadable(lazy(() => import('pages/StepperView')));
const Login = Loadable(lazy(() => import('pages/auth/Login')));
const Registration = Loadable(lazy(() => import('pages/auth/Registration')));
const RegistrationComplete = Loadable(
  lazy(() => import('pages/auth/RegistrationComplete'))
);
const RecoveryPassword = Loadable(
  lazy(() => import('pages/auth/RecoveryPassword'))
);
const RecoveryPasswordEmailSent = Loadable(
  lazy(() => import('pages/auth/RecoveryPasswordEmailSent'))
);
const ResetPassword = Loadable(lazy(() => import('pages/auth/ResetPassword')));
const PasswordConfirmation = Loadable(
  lazy(() => import('pages/auth/PasswordConfirmation'))
);
// const SetPassword = Loadable(lazy(() => import('pages/auth/SetPassword')));
const PersonalProfile = Loadable(
  lazy(() => import('pages/auth/PersonalProfile'))
);

// const Dashboard = Loadable(lazy(() => import('pages/Dashboard')));
// const FAQ = Loadable(lazy(() => import('pages/FAQ')));
const Notifications = Loadable(lazy(() => import('pages/Notifications')));

const DashboardV2 = Loadable(lazy(() => import('pages/DashboardV2')));

const Certification = Loadable(
  lazy(() => import('pages/Internationalization/Certifications'))
);

const CustomHouseOffice = Loadable(
  lazy(() => import('pages/Internationalization/CustomHouseOffice'))
);

const ContentType = Loadable(
  lazy(() => import('pages/Internationalization/CustomHouseOffice/ContentType'))
);

const Master = Loadable(
  lazy(() => import('pages/Internationalization/CustomHouseOffice/Master'))
);

const Expert = Loadable(
  lazy(() => import('pages/Internationalization/CustomHouseOffice/Expert'))
);

const MaterialLibrary = Loadable(
  lazy(() => import('pages/Sustainability/MaterialLibrary'))
);

const CircularityTool = Loadable(
  lazy(() => import('pages/Sustainability/CircularityTool'))
);

const NewSurvey = Loadable(
  lazy(() => import('pages/Sustainability/CircularityTool/NewSurvey'))
);

const DesignWoods = Loadable(
  lazy(() => import('pages/Sustainability/DesignWoods'))
);

const GeneralSurvey = Loadable(
  lazy(() => import('pages/Sustainability/GeneralSurvey'))
);

const SurveyForm = Loadable(
  lazy(() => import('pages/Sustainability/GeneralSurvey/SurveyForm'))
);

const Academy = Loadable(
  lazy(() => import('pages/Training&Consulting/Academy'))
);

const AcademyType = Loadable(
  lazy(() => import('pages/Training&Consulting/Academy/AcademyType'))
);

const AcademyCategory = Loadable(
  lazy(
    () =>
      import('pages/Training&Consulting/Academy/AcademyType/AcademyCategory')
  )
);

const AcademyDetail = Loadable(
  lazy(
    () => import('pages/Training&Consulting/Academy/AcademyType/AcademyDetail')
  )
);

const AcademyFinanceReq = Loadable(
  lazy(
    () =>
      import(
        'pages/Training&Consulting/Academy/AcademyType/AcademyDetail/AcademyFinanceReq'
      )
  )
);

const AcademyMoreInfoReq = Loadable(
  lazy(
    () =>
      import(
        'pages/Training&Consulting/Academy/AcademyType/AcademyDetail/AcademyMoreInfoReq'
      )
  )
);

const IntellectualProperty = Loadable(
  lazy(() => import('pages/Training&Consulting/IntellectualProperty'))
);

const IntellectualList = Loadable(
  lazy(
    () =>
      import('pages/Training&Consulting/IntellectualProperty/IntellectualList')
  )
);

const IPConvention = Loadable(
  lazy(
    () => import('pages/Training&Consulting/IntellectualProperty/IPConvention')
  )
);

const IPCourse = Loadable(
  lazy(() => import('pages/Training&Consulting/IntellectualProperty/IPCourse'))
);

const OnDemandList = Loadable(
  lazy(
    () => import('pages/Training&Consulting/IntellectualProperty/OnDemandList')
  )
);

const IntellectualDetail = Loadable(
  lazy(
    () =>
      import(
        'pages/Training&Consulting/IntellectualProperty/IntellectualList/IntellectualDetail'
      )
  )
);

const OnDemandDetail = Loadable(
  lazy(
    () =>
      import(
        'pages/Training&Consulting/IntellectualProperty/OnDemandList/OnDemandDetail'
      )
  )
);

const FinanceAndTax = Loadable(
  lazy(() => import('pages/Training&Consulting/Finance'))
);

const FinanceList = Loadable(
  lazy(() => import('pages/Training&Consulting/Finance/FinanceList'))
);

const FinanceDetail = Loadable(
  lazy(
    () => import('pages/Training&Consulting/Finance/FinanceList/FinanceDetail')
  )
);

const FinanceWebinarList = Loadable(
  lazy(() => import('pages/Training&Consulting/Finance/FinanceWebinarList'))
);

const FinanceWebinarDetail = Loadable(
  lazy(
    () =>
      import(
        'pages/Training&Consulting/Finance/FinanceWebinarList/FinanceWebinarDetail'
      )
  )
);

const Library = Loadable(
  lazy(() => import('pages/Training&Consulting/Library'))
);
// const WorkInProgressLibrary = Loadable(
//   lazy(() => import('pages/Training&Consulting/WorkInProgressLibrary'))
// );
const StudyCenter = Loadable(
  lazy(() => import('pages/Training&Consulting/StudyCenter'))
);
const StudyCenterCategoriesList = Loadable(
  lazy(
    () =>
      import('pages/Training&Consulting/StudyCenter/StudyCenterCategoriesList')
  )
);
const StudyCenterCategory = Loadable(
  lazy(
    () => import('pages/Training&Consulting/StudyCenter/StudyCenterCategory')
  )
);

const CustomHouseServices = Loadable(
  lazy(() => import('pages/Internationalization/CustomHouseOffice/Services'))
);

const MaterialDetails = Loadable(
  lazy(() => import('pages/Sustainability/MaterialLibrary/MaterialDetails'))
);

const MaterialProducerDetail = Loadable(
  lazy(
    () =>
      import(
        'pages/Sustainability/MaterialLibrary/MaterialDetails/MaterialProducerDetail'
      )
  )
);

const CertificationTab = Loadable(
  lazy(
    () => import('pages/Internationalization/Certifications/CertificationTab')
  )
);

const CertificationMoreContent = Loadable(
  lazy(
    () =>
      import(
        'pages/Internationalization/Certifications/CertificationMoreContent'
      )
  )
);

const Content = Loadable(lazy(() => import('pages/Content')));
const StudyCenterDetails = Loadable(
  lazy(() => import('pages/Training&Consulting/StudyCenter/details'))
);

const ItalianWoodExchange = Loadable(
  lazy(() => import('pages/Training&Consulting/ItalianWoodStock'))
);
