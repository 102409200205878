import { IntlShape } from 'react-intl';

const htmlParserOptions = {
  br: () => <br />,
  b: (str: string) => <b>{str}</b>,
  email: (str: string) => (
    <a
      href={'mailto: plus@federlegnoarredo.it'}
      rel="noreferrer"
      style={{ color: 'black' }}
    >
      {str}
    </a>
  ),
};

export const authTranslations = (intl: IntlShape) => ({
  auth: {
    login: {
      title: intl.formatMessage({
        defaultMessage: 'Benvenuto',
        description: 'Login Page title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          'I servizi di FLA Plus sono riservati agli associati FederlegnoArredo. ',
        description: 'Login Page subtitle',
      }),
      registerText: intl.formatMessage({
        defaultMessage: 'Non hai ancora un account?',
        description: 'Previous text Login Page register action in subtitle',
      }),
      registerCTA: intl.formatMessage({
        defaultMessage: 'Registrati',
        description: 'Login Page register action in subtitle',
      }),
      fields: {
        username: intl.formatMessage({
          defaultMessage: 'Email aziendale',
          description: 'Login Page username input label',
        }),
        password: intl.formatMessage({
          defaultMessage: 'Password',
          description: 'Login Page password input label',
        }),
        rememberMe: intl.formatMessage({
          defaultMessage: 'Ricordami',
          description: 'Login Page remember me checkbox',
        }),
      },
      buttons: {
        signIn: intl.formatMessage({
          defaultMessage: 'ACCEDI',
          description: 'Login Page Sign In Button label',
        }),
      },
      links: {
        forgotPassword: intl.formatMessage({
          defaultMessage: 'Hai dimenticato la password?',
          description: 'Login Page forgot password link',
        }),
        discoverFLA: intl.formatMessage({
          defaultMessage: 'SCOPRI COME ASSOCIARSI A FLA',
          description: 'Login Page discover FLA link',
        }),
      },
      errors: {
        email: intl.formatMessage({
          defaultMessage: 'Si prega di inserire un indirizzo email valido',
          description: 'Email field error message',
        }),
        loginKey: intl.formatMessage({
          defaultMessage:
            'You must enter at least one between Username, Email address or Phone number',
          description: 'Login Page combined login key field error message',
        }),
        password: intl.formatMessage({
          defaultMessage: 'Password richiesta',
          description: 'Login page password field error message',
        }),
        required: intl.formatMessage({
          defaultMessage: 'Campo richiesto',
          description: 'Generic required field error message',
        }),
      },
      snackbars: {
        errors: {
          invalidUsernameOrPassword: intl.formatMessage({
            defaultMessage:
              'Nome utente o password non validi. Per favore riprovare.',
            description:
              'Login page invalid username or password snackbar error',
          }),
        },
      },
    },
    register: {
      code: {
        title: intl.formatMessage({
          defaultMessage: 'Registrazione',
          description: 'Register Associated Page title',
        }),
        subtitle: intl.formatMessage(
          {
            defaultMessage:
              'Se sei un’azienda associata a FederlegnoArredo puoi creare un account Plus inserendo i dati aziendali e il codice univoco condiviso dalla tua organizzazione, con il quale anche più persone dell’azienda possono creare un account personale per accedere. Se non sei in possesso del codice, scrivi a <email>plus@federlegnoarredo.it</email> per riceverlo e creare subito il tuo account. Tutti i dati sono obbligatori per poter continuare.',
            description: 'Register Associated Page subtitle',
          },
          { ...htmlParserOptions }
        ),
        fields: {
          uniqueCode: intl.formatMessage({
            defaultMessage: 'Codice Univoco',
            description: 'Register Associated Page unique code field',
          }),
          vatNumber: intl.formatMessage({
            defaultMessage: 'Partita IVA',
            description: 'Register Associated Page vat number field',
          }),
          businessName: intl.formatMessage({
            defaultMessage: 'Ragione Sociale',
            description: 'Register Associated Page business name field',
          }),
        },
      },
      generalData: {
        title: intl.formatMessage({
          defaultMessage: 'Creazione Account Personale',
          description: 'Register Private Page title',
        }),
        subtitle: intl.formatMessage(
          {
            defaultMessage:
              'Inserisci i tuoi dati personali. <br></br> *Questi dati sono obbligatori per continuare.',
            description: 'Register Private Page subtitle',
          },
          { ...htmlParserOptions }
        ),
        fields: {
          email: intl.formatMessage({
            defaultMessage: 'Email aziendale',
            description: 'Register Private Page email input field',
          }),
          firstName: intl.formatMessage({
            defaultMessage: 'Nome',
            description: 'Register Private Page firstName input field',
          }),
          lastName: intl.formatMessage({
            defaultMessage: 'Cognome',
            description: 'Register Private Page lastName input field',
          }),
          role: intl.formatMessage({
            defaultMessage: 'Ruolo aziendale',
            description: 'Register Private Page role input field',
          }),
          phone: intl.formatMessage({
            defaultMessage: 'Telefono',
            description: 'Register Private Page phone input field',
          }),
          privacyPolicy: intl.formatMessage({
            defaultMessage:
              'Ho preso visione dell’informativa sul trattamento dei dati personali.',
            description: 'Register Private Page privacy policy input field',
          }),
        },
      },
      password: {
        title: intl.formatMessage({
          defaultMessage: 'Impostazione Password',
          description: 'Register Associated password Page title',
        }),
        subtitle: intl.formatMessage({
          defaultMessage: 'Crea una password per entrare in Plus',
          description: 'Register Associated password Page subtitle',
        }),
      },
      common: {
        links: {
          login: intl.formatMessage({
            defaultMessage: 'Sei già registrato?',
            description: 'Register Associated Page text before link',
          }),
          loginCta: intl.formatMessage({
            defaultMessage: 'Accedi',
            description: 'Register Associated Page link text',
          }),
          privacyPolicy: intl.formatMessage({
            defaultMessage: 'Privacy Policy',
            description: 'Register Private Page privacy policy link',
          }),
        },
        buttons: {
          continues: intl.formatMessage({
            defaultMessage: 'Continua',
            description: 'Register Associated Page continue button label',
          }),
          confirm: intl.formatMessage({
            defaultMessage: 'Conferma',
            description: 'Register Associated Page confirm button label',
          }),
        },
        errors: {
          passwordFormatNotValid: intl.formatMessage({
            defaultMessage:
              'La password deve essere lunga almeno 8 caratteri e contenere un numero, una lettera minuscola, una lettera maiuscola e un carattere speciale',
            description: 'Register Private page password fromat not valid',
          }),
          passwordMismatch: intl.formatMessage({
            defaultMessage: 'Le password non corrispondono',
            description: 'Register Private page password fromat not valid',
          }),
        },
      },
      modals: {
        notFound: {
          title: intl.formatMessage({
            defaultMessage: 'Azienda non associata',
            description: 'auth register modals notFound title',
          }),
          msg: intl.formatMessage({
            defaultMessage:
              'Se non sei ancora Associato guarda gli step da seguire per associarsi e invia la tua candidatura.',
            description: 'auth register modals notFound msg',
          }),
          confirm: intl.formatMessage({
            defaultMessage: 'Scopri come Associarsi',
            description: 'auth register modals notFound confirm',
          }),
        },
        notPay: {
          title: intl.formatMessage({
            defaultMessage: 'Aggiorna il pagamento',
            description: 'auth register modals notPay title',
          }),
          msg: intl.formatMessage({
            defaultMessage:
              'Purtroppo non puoi eseguire la registrazione in Plus perché devi aggiornare il pagamento della tua quota associativa.',
            description: 'auth register modals notPay msg',
          }),
          confirm: intl.formatMessage({
            defaultMessage: 'Contattaci',
            description: 'auth register modals notPay confirm',
          }),
        },
      },
    },
    setOrResetPassword: {
      titleSet: intl.formatMessage({
        defaultMessage: 'Imposta la Password',
        description: 'SetPassowrd Page title',
      }),
      subtitleSet: intl.formatMessage({
        defaultMessage: 'Crea una password per entrare in Plus',
        description: 'SetPassword Page subtitle',
      }),
      titleReset: intl.formatMessage({
        defaultMessage: 'Reimposta la Password',
        description: 'ResetPassowrd Page title',
      }),
      subtitleReset: intl.formatMessage({
        defaultMessage: 'Importa nuova password',
        description: 'ResetPassword Page subtitle',
      }),
      fields: {
        newPassword: intl.formatMessage({
          defaultMessage: 'Nuova Password',
          description: 'SetPassword Page new password input field',
        }),
        confirmPassowrd: intl.formatMessage({
          defaultMessage: 'Conferma password',
          description: 'SetPassword Page confirm password input field',
        }),
      },
      passwordRequirements: {
        title: intl.formatMessage({
          defaultMessage: 'Requisiti password:',
          description: 'SetPassword Page password requirements title',
        }),
        firstRequirement: intl.formatMessage({
          defaultMessage: 'Contenere almeno 8 caratteri',
          description: 'SetPassword Page first password requirement',
        }),
        secondRequirement: intl.formatMessage({
          defaultMessage: 'Contenere lettere minuscole',
          description: 'SetPassword Page second password requirement',
        }),
        thirdRequirement: intl.formatMessage({
          defaultMessage: 'Contenere almeno un numero',
          description: 'SetPassword Page third password requirement',
        }),
        fourthRequirement: intl.formatMessage({
          defaultMessage: 'Contenere almeno una lettera maiuscola',
          description: 'SetPassword Page fourth password requirement',
        }),
        fifthRequirement: intl.formatMessage({
          defaultMessage: 'Contenere almeno un carattere speciale',
          description: 'SetPassword Page fifth password requirement',
        }),
      },
      buttons: {
        confirm: intl.formatMessage({
          defaultMessage: 'CONFERMA',
          description: 'SetPassword Page confirm button label',
        }),
        send: intl.formatMessage({
          defaultMessage: 'INVIA',
          description: 'ResetPassword Page send button label',
        }),
      },
      errors: {
        emailRequired: intl.formatMessage({
          defaultMessage: 'Email field is required',
          description:
            'Reset passwword Page email field required error message',
        }),
        emailNotValid: intl.formatMessage({
          defaultMessage: 'Please enter a valid email address',
          description: 'Reset passwword Page Invalid Email field error message',
        }),
        passwordFormatNotValid: intl.formatMessage({
          defaultMessage:
            'Password must  be at least 8 characters long and contain a number, a lowercase letter, an uppercase letter and a special character',
          description: 'Reset password Page Invalid Email format error message',
        }),
      },
      snackbars: {
        success: {
          emailSent: intl.formatMessage({
            defaultMessage: 'An email with reset info was sent to your inbox',
            description: 'Reset password page email sent snackbar message',
          }),
        },
        errors: {
          usernameNotFound: intl.formatMessage({
            defaultMessage: 'Email address not found, please try again',
            description: 'Reset password page email not found snackbar error',
          }),
        },
      },
    },
    recoveryPassword: {
      title: intl.formatMessage({
        defaultMessage: 'Recupera Password',
        description: 'RecoveryPassword Page title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          'Inserisci l’indirizzo email che hai fornito per registrarti, poi segui le istruzioni che riceverai per reimpostare la tua password.',
        description: 'RecoveryPassword Page subtitle',
      }),
      fields: {
        email: intl.formatMessage({
          defaultMessage: "Inserisci l'email",
          description: 'RecoveryPassword Page email input field',
        }),
      },
      buttons: {
        send: intl.formatMessage({
          defaultMessage: 'INVIA',
          description: 'RecoveryPassword Page send button label',
        }),
      },
    },
    passwordConfirmation: {
      title: intl.formatMessage({
        defaultMessage: 'Password reimpostata con successo',
        description: 'PasswordConfirmation Page title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          'Effettua la login per accedere alla piattaforma, utilizza le credenziali fornite da Plus',
        description: 'PasswordConfirmation Page subtitle',
      }),
      buttons: {
        login: intl.formatMessage({
          defaultMessage: 'LOGIN',
          description: 'PassConfirmation Page login button label',
        }),
      },
    },
    personalProfile: {
      title: intl.formatMessage({
        defaultMessage: 'Gestione Profilo',
        description: 'PersonalProfile Page title',
      }),
      tabs: {
        info: intl.formatMessage({
          defaultMessage: 'Informazione profilo',
          description: 'PersonalProfile Page info tab label',
        }),
        password: intl.formatMessage({
          defaultMessage: 'Cambia Password',
          description: 'PersonalProfile Page password tab label',
        }),
        feedback: intl.formatMessage({
          defaultMessage: 'Invia Feedback',
          description: 'Personal Profile Page feedback tab label',
        }),
        community: intl.formatMessage({
          defaultMessage: 'Community',
          description: 'PersonalProfile Page community tab label',
        }),
        interests: intl.formatMessage({
          defaultMessage: 'Interessi',
          description: 'PersonalProfile Page interests tab label',
        }),
      },
      feedbackTab: {
        text: intl.formatMessage(
          {
            defaultMessage:
              'Il nostro obiettivo è presentarti la miglior esperienza di fruizione di dati e contenuti utili per la crescita sostenibile della tua azienda.<br></br> Questa è una piattaforma in continuo aggiornamento, il tuo contributo è prezioso e ci piacerebbe sentire la tua opinione. ',
            description: 'PersonalProfile feedbackTab text',
          },
          { ...htmlParserOptions }
        ),
        title: intl.formatMessage({
          defaultMessage: 'Riepiloga il tuo feedback',
          description: 'PersonalProfile feedbackTab title',
        }),
        dialog: {
          title: intl.formatMessage({
            defaultMessage: 'Feedback Inviato',
            description: 'PersonalProfile feedbackTab dialog title',
          }),
          subtitle: intl.formatMessage({
            defaultMessage: 'Grazie del tuo contributo',
            description: 'PersonalProfile feedbackTab dialog subtitle',
          }),
          closeButton: intl.formatMessage({
            defaultMessage: 'Chiudi',
            description: 'PersonalProfile feedbackTab dialog button label',
          }),
        },
      },
      communityTab: {
        tableHeaderLabel: {
          name: intl.formatMessage({
            defaultMessage: 'Nome e cognome',
            description:
              'PersonalProfile Page community tableHeader name label',
          }),
          role: intl.formatMessage({
            defaultMessage: 'Ruolo',
            description:
              'PersonalProfile Page community tableHeader role label',
          }),
        },
      },
    },
  },
});
